import React from 'react';
import { Link } from 'gatsby';

const CustomLink = ({ href, children, onClick, className, ...props }) => {
  var regex = /http/;
  if (href.match(regex)) {
    return (
      <a className={className} onClick={onClick} href={href} {...props}>
        {children}
      </a>
    );
  }
  return (
    <Link className={className} onClick={onClick} to={href} {...props}>
      {children}
    </Link>
  );
};

export default CustomLink;
