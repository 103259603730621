import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import Header from "./Header";
import Footer from "./Footer";
import Title from "./Title";
import "../styles/style.scss";

const Layout = ({ children, location }) => (
  <>
    <Title />
    {location ? <Header location={location} /> : null}
    <div className="content" role="main">
      {children}
    </div>
    <Footer />
  </>
);

export default Layout;
