// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calculator-js": () => import("./../../../src/pages/calculator.js" /* webpackChunkName: "component---src-pages-calculator-js" */),
  "component---src-pages-chestionare-js": () => import("./../../../src/pages/chestionare.js" /* webpackChunkName: "component---src-pages-chestionare-js" */),
  "component---src-pages-comunicareelectronica-js": () => import("./../../../src/pages/comunicareelectronica.js" /* webpackChunkName: "component---src-pages-comunicareelectronica-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dosarulelectronic-js": () => import("./../../../src/pages/dosarulelectronic.js" /* webpackChunkName: "component---src-pages-dosarulelectronic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-verificare-js": () => import("./../../../src/pages/verificare.js" /* webpackChunkName: "component---src-pages-verificare-js" */)
}

