import React from 'react';

const FooterLinks = () => {
  return (
    <div className="footer__links">
      <div className="footer__linksJud">
        <h3>Sistemul judiciar în România</h3>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.csm1909.ro/"
            >
              Consiliul Superior al Magistraturii
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.mpublic.ro/"
            >
              Ministerul Public
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.scj.ro/"
            >
              Înalta Curte de Casație și Justiție
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://anp.gov.ro/"
            >
              Administratia Nationala a Penitenciarelor
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.just.ro/?p=2913"
            >
              Direcția Națională de Probațiune
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.onrc.ro/"
            >
              Autoritatea Națională pentru Cetățenie
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.onrc.ro/"
            >
              Oficiul Național al Registrului Comerțului
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.inec.ro/"
            >
              Institutul Național de Expertize Criminalistice
            </a>
          </li>
        </ul>
      </div>
      <div className="footer__linksUtil">
        <h3>Legături utile</h3>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.presidency.ro/"
            >
              Președintele României
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.gov.ro/"
            >
              Guvernul României
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://ec.europa.eu/justice/index_en.htm"
            >
              Comisia Europeană – DG Justice
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://norwaygrants.just.ro/"
            >
              Mecanismul Financiar Norvegian 2009 – 2014
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer noopener" href="http://unbr.ro/">
              Uniunea Națională a Barourilor din România
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.uniuneanotarilor.ro/"
            >
              Uniunea Națională a Notarilor Publici din România
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.executori.ro/"
            >
              Uniunea Națională a Executorilor Judecătorești
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.cmediere.ro/"
            >
              Consiliul de Mediere
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.ccjtm.ro/"
            >
              Colegiul Consilierilor Juridici Timiș
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FooterLinks;
