import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const Title = ({ title }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => {
      return (
        <Helmet>
          <title>
            {`${data.site.siteMetadata.title} ${title ? ` |   ${title}` : ''}`}
          </title>
        </Helmet>
      );
    }}
  />
);
export default Title;
