import React from 'react';
import FooterLinks from './FooterLinks';

const Footer = () => (
  <footer>
    <FooterLinks />
    <div className='footer__copyright'>
      <h3>{new Date().getFullYear()} &copy; Compartiment IT CATM</h3>
    </div>
    <div className='footer__poweredby'>
      <span>Powered by:</span>
      <ul>
        <li>
          <a href='https://fontawesome.com'>Font Awesome</a>
        </li>
        <li>
          <a href='https://www.gatsbyjs.org/'>GatsbyJS</a>
        </li>
        <li>
          <a href='https://fonts.google.com/'>Google Fonts</a>
        </li>
      </ul>
    </div>
  </footer>
);
export default Footer;
