import React from 'react';
import Link from './Link';

const links = [
  {
    to: '/',
    display: 'Acasă',
  },
  {
    to: 'http://portal.just.ro/59/SitePages/acasa_default.aspx?id_inst=59',
    display: 'Portal',
  },
  {
    to: 'https://curteadeapeltimisoara.eu/',
    display: 'Info/Fișă dosar',
  },
  {
    to: 'https://doc.curteapeltimisoara.ro/autentificare',
    display: 'Dosarul electronic',
  },
  {
    to: 'http://portal.just.ro/59/SitePages/contact.aspx?id_inst=59',
    display: 'contact',
  },
];

class Header extends React.Component {
  state = { lastOffset: 0, show: true };

  constructor() {
    super();
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  closeMenu = () => {
    this.toggle.checked = false;
  };

  handleScroll(e) {
    e.stopPropagation();
    const { lastOffset } = this.state;
    const {
      pageYOffset,
      screen: { height },
    } = window;
    // if scrolling down or scrolling more than 1 screen at a time (through link navigation)
    if (
      pageYOffset < lastOffset ||
      pageYOffset - lastOffset > height ||
      pageYOffset < 50 // dont hide when refreshing page by pulling the page down
    ) {
      this.setState({ lastOffset: pageYOffset, show: true });
    } else {
      this.setState({ lastOffset: pageYOffset, show: false });
    }
  }

  isActive(path) {
    const { pathname } = this.props.location;
    let regex = /\//g;
    return pathname.replace(regex, '') === path.replace(regex, '');
  }

  render() {
    const { show } = this.state;
    const headerClass = show ? '' : 'header--hidden';
    return (
      <header className={headerClass}>
        <h1 className="header__logo">
          <Link href="/">
            <div className="logo__text--small">CATM</div>
            <div className="logo__text--large">CA Timișoara</div>
          </Link>
        </h1>
        <input
          type="checkbox"
          ref={(node) => {
            this.toggle = node;
          }}
          id="nav__toggle"
          className="nav__toggle"
        />
        <nav>
          <ul>
            {links.map((l) => (
              <li key={l.to}>
                <Link
                  href={l.to}
                  onClick={this.closeMenu}
                  className={this.isActive(l.to) ? 'active' : ''}
                >
                  {l.display}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        {/* eslint-disable-next-line */}
        <label htmlFor="nav__toggle" className="nav__toggleLabel">
          <span className="nav__toggleLabelText">Meniu</span>
          <span className="nav__toggleLabelLines" />
        </label>
      </header>
    );
  }
}
export default Header;
